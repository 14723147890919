
import { defineComponent, ref, onMounted, onBeforeUnmount, computed, inject } from 'vue';
import CrmCustomerLeadsFilterModal from '@/components/CrmCustomerLead/CrmCustomerLeadsFilterModal.vue';
import CrmCustomerLeadSettingsPopup from '@/components/CrmCustomerLead/CrmCustomerLeadSettingsPopup.vue';
import CrmCustomerLeadSettingManageTypesModal from '@/components/CrmCustomerLead/CrmCustomerLeadSettingManageTypesModal.vue';
import CrmCustomerLeadSettingManageSourcesModal from '@/components/CrmCustomerLead/CrmCustomerLeadSettingManageSourcesModal.vue';
import CrmCustomerPageHeader from '@/components/CrmCustomers/CrmCustomerPageHeader.vue';
import { CrmCustomerLeadListModel, CrmCustomerLeadListFilter } from '@/models/CrmCustomers';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import router from '@/router';
import { generalStore } from '@/store';
import { LeadStatus, LeadStatusColors, CrmCustomerLeadSettingActionTypes, QuickReplyMode } from '@/models/Enums';
import QuickReplyPopup from '@/components/CrmCustomers/QuickReplyPopup.vue';
import { SendCrmCustomerMessageApiRequest } from '@/models/Interfaces';

export default defineComponent({
    name: 'CrmCustomerLeads',
    components: {
        CrmCustomerPageHeader,
        CrmCustomerLeadsFilterModal,
        CrmCustomerLeadSettingsPopup,
        CrmCustomerLeadSettingManageTypesModal,
        CrmCustomerLeadSettingManageSourcesModal,
        QuickReplyPopup
    },
    async setup() {
        const emitter: any = inject('emitter');
        const loaded = ref(false);
        const busy = ref(false);
        const hasNext = ref(false);
        const modalCrmCustomerLeadsFilter = ref<InstanceType<typeof CrmCustomerLeadsFilterModal>>();
        const modalCrmCustomerLeadSettings = ref<InstanceType<typeof CrmCustomerLeadSettingsPopup>>();
        const modalCrmCustomerLeadSettingManageTypes = ref<InstanceType<typeof CrmCustomerLeadSettingManageTypesModal>>();
        const modalCrmCustomerLeadSettingManageSources = ref<InstanceType<typeof CrmCustomerLeadSettingManageSourcesModal>>();
        const modalLeadSettingManageReply = ref<InstanceType<typeof QuickReplyPopup>>();
        const expandedRows = ref<number[]>([]);
        const selectedLeadForQuickReply = ref({ crmCustomerLeadId: '', crmCustomerId: '' });

        const localCurrencySymbol = generalStore.state.localCurrencySymbol;
        const crmCustomerLeads = ref<CrmCustomerLeadListModel[]>([]);
        const filter = ref(new CrmCustomerLeadListFilter());
        const total = ref(0);
        const totalFiltered = ref(0);

        function back() {
            router.go(-1);
        }

        function ShowFilter() {
            modalCrmCustomerLeadsFilter.value?.open(filter.value);
        }

        const hasFilter = computed<boolean>(() => {
            if (filter.value.businessClientId) {
                return true;
            }
            if (filter.value.createDateFrom) {
                return true;
            }
            if (filter.value.createDateTo) {
                return true;
            }
            if (filter.value.quoteTotalQuantity) {
                return true;
            }
            if (filter.value.leadSourceId) {
                return true;
            }
            if (filter.value.leadTypeId) {
                return true;
            }
            if (filter.value.status) {
                return true;
            }
            return false;
        });

        const GetCrmCustomerLeadsApiCall = async () => {
            swal.fire({
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    swal.showLoading();
                },
                showConfirmButton: false
            });
            busy.value = true;
            const apiResult = await api.getCrmCustomerLeadList(filter.value);
            if (apiResult.errorMessage || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage || 'No data'
                });
                return;
            }
            hasNext.value = apiResult.data.hasNext || false;
            if (filter.value.reference == null) {
                total.value = apiResult.data.total || 0;
                totalFiltered.value = apiResult.data.totalFiltered;
            }
            if (apiResult.data && crmCustomerLeads.value && crmCustomerLeads.value.length) {
                crmCustomerLeads.value = [...crmCustomerLeads.value, ...apiResult.data.crmCustomerLeads];
            } else {
                crmCustomerLeads.value = apiResult.data.crmCustomerLeads;
            }
            filter.value.reference = apiResult.data.reference;
            busy.value = false;
            swal.close();
        };

        async function applyFilter(filterObj: CrmCustomerLeadListFilter) {
            filter.value = filterObj;
            crmCustomerLeads.value = [];
            expandedRows.value = [];
            filter.value.reference = null;
            await GetCrmCustomerLeadsApiCall();
        }
        function CreateLead() {
            router.push({ name: 'crm-customer-lead-create' });
        }
        async function GetCrmCustomerLeads() {
            crmCustomerLeads.value = [];
            expandedRows.value = [];
            filter.value.reference = null;
            await GetCrmCustomerLeadsApiCall();
        }
        async function showAll() {
            filter.value = new CrmCustomerLeadListFilter();
            crmCustomerLeads.value = [];
            expandedRows.value = [];
            await GetCrmCustomerLeadsApiCall();
        }
        async function sotrBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;
            filter.value.reference = null;
            crmCustomerLeads.value = [];
            expandedRows.value = [];
            await GetCrmCustomerLeadsApiCall();
        }
        function getArrowClass(sort: string) {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }
        function goToDetails(leadId: string, crmCustomerId: string) {
            router.push({ name: 'crm-customer-lead-details', params: { crmCustomerId: crmCustomerId, crmCustomerLeadId: leadId } });
        }

        async function ListenerGetCrmCustomerLeads() {
            if (hasNext.value && !busy.value) {
                await GetCrmCustomerLeadsApiCall();
            }
        }

        function onBeforeUnMountedCall() {
            emitter.off('isBottom', ListenerGetCrmCustomerLeads);
        }

        function showSettings() {
            modalCrmCustomerLeadSettings.value?.open();
        }

        function ExportXls() {
            // TODO
        }

        function toggleDetails(index: number) {
            if (expandedRows.value.includes(index)) {
                expandedRows.value = expandedRows.value.filter(i => i !== index);
            } else {
                expandedRows.value.push(index);
            }
        }

        function onSelectAction(action: CrmCustomerLeadSettingActionTypes) {
            if (action == CrmCustomerLeadSettingActionTypes.ManageTypes) {
                modalCrmCustomerLeadSettingManageTypes.value?.open();
            }
            if (action == CrmCustomerLeadSettingActionTypes.ManageSources) {
                modalCrmCustomerLeadSettingManageSources.value?.open();
            }
            if (action == CrmCustomerLeadSettingActionTypes.ManageReply) {
                modalLeadSettingManageReply.value?.open(true, false, QuickReplyMode.Manage);
            }
        }

        function openReplyMessageBySelect(crmCustomerLeadId: string, crmCustomerId: string) {
            selectedLeadForQuickReply.value = {
                crmCustomerId: crmCustomerId,
                crmCustomerLeadId: crmCustomerLeadId
            };
            modalLeadSettingManageReply.value?.open(false, true, QuickReplyMode.Select);
        }

        async function onSelectQuickReply(quickReplyId: string) {
            console.log(selectedLeadForQuickReply.value);
            swal.showLoading();
            const apiRequest: SendCrmCustomerMessageApiRequest = {
                crmCustomerId: selectedLeadForQuickReply.value.crmCustomerId,
                message: '',
                quickReplyId: quickReplyId,
                crmCustomerLeadId: selectedLeadForQuickReply.value.crmCustomerLeadId,
                attachments: []
            };
            const apiResult = await api.SendCrmCustomerMessage(apiRequest);
            if (apiResult.validationErrors?.length) {
                const html = apiResult.validationErrors
                    .map(x => {
                        return `<div>${x.key}: ${x.msg}</div>`;
                    })
                    .join(',');
                swal.fire({
                    icon: 'error',
                    html: html
                });
                return;
            }
            if (apiResult.errorMessage || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage || 'No data'
                });
                return;
            }
            modalLeadSettingManageReply.value?.close();
            swal.close();
        }

        const onMountedCall = async () => {
            emitter.on('isBottom', ListenerGetCrmCustomerLeads);
            filter.value.reference = null;
            await GetCrmCustomerLeadsApiCall();
            loaded.value = true;
        };

        onMounted(onMountedCall);
        onBeforeUnmount(onBeforeUnMountedCall);
        return {
            modalCrmCustomerLeadsFilter,
            filter,
            crmCustomerLeads,
            loaded,
            hasFilter,
            total,
            totalFiltered,
            localCurrencySymbol,
            getArrowClass,
            GetCrmCustomerLeads,
            ShowFilter,
            showAll,
            sotrBy,
            goToDetails,
            applyFilter,
            back,
            CreateLead,
            expandedRows,
            toggleDetails,
            LeadStatus,
            LeadStatusColors,
            showSettings,
            ExportXls,
            modalCrmCustomerLeadSettings,
            onSelectAction,
            modalCrmCustomerLeadSettingManageTypes,
            modalCrmCustomerLeadSettingManageSources,
            modalLeadSettingManageReply,
            openReplyMessageBySelect,
            onSelectQuickReply
        };
    }
});
