
import { defineComponent, ref, PropType, computed } from 'vue';
import { IdNameActive, CrmCustomerActivityTagEditModel } from '@/models/CrmCustomers';
import swal from 'sweetalert2';
import q from 'q';

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true
        },
        headers: {
            type: Array as PropType<string[]>,
            required: true
        },
        buttonText: {
            type: String,
            required: true
        },
        onSaveClick: {
            type: Function as PropType<(item: CrmCustomerActivityTagEditModel) => Promise<IdNameActive | null>>,
            required: true
        },
        onDeleteClick: {
            type: Function as PropType<(id: CrmCustomerActivityTagEditModel) => Promise<boolean>>,
            required: true
        }
    },
    components: {},
    name: 'CrmCustomerIdNameActiveEditor',
    async setup(props, { emit }) {
        const rows = ref<CrmCustomerActivityTagEditModel[]>([]);
        const newEntityExist = computed(() => rows.value.some(x => !x.id));
        function setRows(rowParams: IdNameActive[]) {
            rows.value = rowParams.map(x => ({
                id: x.id,
                name: x.name,
                active: x.active,
                editMode: false,
                copy: { name: x.name, active: x.active }
            }));
        }
        function close() {
            emit('close');
        }
        async function applyChanges(row: CrmCustomerActivityTagEditModel) {
            console.log(row);
            if (!row.copy.name) {
                return;
            }
            if (row.copy.name == row.name && row.copy.active == row.active) {
                if (!row.id) {
                    rows.value.splice(0, 1);
                }
                row.editMode = false;
                return;
            }
            swal.showLoading();
            const result = await props.onSaveClick(row);
            if (!result) {
                return;
            }
            if (!row.id) {
                row.id = result.id;
            }
            row.name = result.name;
            row.active = result.active;
            row.editMode = false;
            swal.close();
            emit('applyChanges', { active: row.active, id: row.id, name: row.name });
        }
        async function setNotActiveOrSkip(item: CrmCustomerActivityTagEditModel) {
            await q.delay(200);
            item.copy.active = false;
            applyChanges(item);
        }
        function toEditModel(row: CrmCustomerActivityTagEditModel) {
            row.copy = {
                name: row.name,
                active: row.active
            };
            row.editMode = true;
        }
        function cancelChanges(row: CrmCustomerActivityTagEditModel) {
            if (!row.id) {
                rows.value.splice(0, 1);
            }
            row.editMode = false;
        }
        function addNewEntity() {
            rows.value.unshift({ id: null, name: '', active: true, editMode: true, copy: { name: '', active: true } });
        }
        async function deleteEntity(row: CrmCustomerActivityTagEditModel, rowIndex: number) {
            if (!row.id) {
                return;
            }
            const result = await props.onDeleteClick(row);
            if (!result) {
                return;
            }
            rows.value.splice(rowIndex, 1);
        }

        return {
            close,
            setRows,
            rows,
            toEditModel,
            applyChanges,
            addNewEntity,
            newEntityExist,
            cancelChanges,
            deleteEntity,
            setNotActiveOrSkip
        };
    }
});
