import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CrmCustomerIdNameActiveEditor = _resolveComponent("CrmCustomerIdNameActiveEditor")
  const _component_OverlayModal = _resolveComponent("OverlayModal")

  return (_openBlock(), _createBlock(_component_OverlayModal, {
    isOpen: _ctx.isOpen,
    "onUpdate:isOpen": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isOpen = $event)),
    modalWidth: 532
  }, {
    default: _withCtx(() => [
      _createVNode("div", null, [
        _createVNode(_component_CrmCustomerIdNameActiveEditor, {
          title: _ctx.$t('title.manage-activity-sources'),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close())),
          onApplyChanges: _ctx.applyChanges,
          onSaveClick: _ctx.onSaveClick,
          onDeleteClick: _ctx.onDeleteClick,
          headers: [_ctx.$t('label.name'), _ctx.$t('title.status')],
          buttonText: _ctx.$t('btn.new-source'),
          ref: "crmCustomerActivityLocationPropsEditor"
        }, null, 8, ["title", "onApplyChanges", "onSaveClick", "onDeleteClick", "headers", "buttonText"])
      ])
    ]),
    _: 1
  }, 8, ["isOpen"]))
}